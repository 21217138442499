<template>
  <div class="order">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-12 bg-white rounded p-0">
            <div class="border-bottom p-3">订单信息</div>
            <div class="container p-0" >
                <div class="row pb-3">
                    <div class="col-4 px-3 mt-3">
                        <span>流水号：</span>
                        <span class="text-secondary">{{ReserveRecord[0].id}}</span>
                    </div>
                    <div class="col-4 px-3 mt-3">
                        <span>订单类型：</span>
                        <span class="text-secondary">{{getreserveTypeValue(ReserveRecord[0].type)}}</span>
                    </div>
                    <div class="col-4 px-3 mt-3">
                        <span>交易状态：</span>
                        <span class="text-secondary">{{getreserveStatusValue(ReserveRecord[0].reserveStatus)}}</span>
                    </div>
                    <div class="col-4 px-3 mt-3">
                        <span>交易金额：</span>
                        <span class="text-danger">{{ReserveRecord[0].prepaid}}元</span>
                    </div>
                    <div class="col-4 px-3 mt-3" v-if="ReserveRecord[0].reserveDate">
                        <span>交易时间：</span>
                        <span class="text-secondary">{{ReserveRecord[0].reserveDate}}</span>
                    </div>
                    <div class="col-4 px-3 mt-3">
                        <span>带看时间：</span>
                        <span class="text-secondary">{{ReserveRecord[0].reserveDate}}</span>
                    </div>
                </div>
            </div>
        </div>

            <div class="col-12 bg-white rounded p-0 mt-3">
                <div class="border-bottom p-3"   >地块信息</div>
                <div class="clearfix p-3" >
                    <div class="float-left">
                        <van-image width="200" height="125" fit="cover" lazy-load src="http://47.99.63.89:8080/static/portal/need/9bad3f15-9ec3-4919-b3be-e6f8fae12c08.jpg" />
                    </div>
                    <div class="float-left pl-2 w-65">
                        <router-link to="/servicecentre">{{userNeeds.title}}</router-link>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">地块编号：</span>
                            <span class="ml-2">{{userNeeds.id}}</span>
                        </div>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">土地用途：</span>
                            <span class="ml-2"  v-for="(m,i) in userNeeds.userNeedsAgriculturalLands " :key="i">{{getUseLandValue(m.landUse)}}</span>

                            <span class="text-secondary ml-4">流转类型：</span>
                            <span class="ml-2">{{getcirculationTypeValue(userNeeds.flowType)}}</span>

                            <span class="text-secondary ml-4">流转年限：</span>
                            <span class="ml-2">{{userNeeds.years}} 年</span>

                            <span class="text-secondary ml-4">土地面积：</span>
                            <span class="ml-2">{{userNeeds.measureArea}} 亩</span>
                        </div>
                        <div class="f-14 mt-1"  v-if="userNeeds.serviceTag">
                            <span class="text-secondary">适合经营：</span>
                            <span class="ml-2">{{getTagsListValue(userNeeds.serviceTag)}}</span>
                        </div>

                        <div class="f-14 mt-1">
                            <span class="text-secondary">土地地点：</span>
                            <span class="ml-2">{{userNeeds.address}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 bg-white rounded p-0 mt-3">
                <div class="border-bottom p-3">服务商信息</div> 
                <div class="clearfix p-3">
                    <div class="float-left">
                        <van-image width="200" height="125" fit="cover" lazy-load :src="require('@/static/img/s_1.png')" />
                    </div>
                    <div class="float-left pl-2 w-65">
                        <router-link to="/servicecentre">江西省抚州市土地流转中心</router-link>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">承接经纪人：</span>
                            <span class="ml-2">吴先生</span>
                        </div>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">经纪人电话：</span>
                            <span class="ml-2">15044885911</span>
                        </div>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">服务区域：</span>
                            <span class="ml-2">江西抚州市</span>
                        </div>
                        <div class="f-14 mt-1">
                            <span class="text-secondary">企业地址：</span>
                            <span class="ml-2">江西省抚州市临川区上顿渡镇东方威尼斯302室</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 bg-white rounded p-0 mt-3" v-if="ReserveRecord[0].reserveStatus==0">
                <div class="border-bottom p-3">支付方式</div> 
                <div class="clearfix p-3">
                    <b-form-radio-group
                        v-model="payWay"
                    >
                        <div @click="payWay = 'alipay'" class="float-left border rounded-10 cursor-default p-4" :class="{'border-success' : payWay == 'alipay'}" style="position: relative;">
                            <b-form-radio value="alipay" style="position: absolute;top:5px;left:10px"></b-form-radio>
                            <van-image width="144" height="50" fit="cover" lazy-load :src="require('@/assets/icon/zfb.png')" />
                        </div>
                        <div @click="payWay = 'wechat'" class="float-left border rounded-10 cursor-default p-4 ml-4" :class="{'border-success' : payWay == 'wechat'}" style="position: relative;">
                            <b-form-radio value="wechat" style="position: absolute;top:5px;left:10px"></b-form-radio>
                            <van-image width="144" height="50" fit="cover" lazy-load :src="require('@/assets/icon/wxsm.png')" />
                        </div>
                        <div @click="payWay = 'balance'" class="float-left border rounded-10 cursor-default p-4 ml-4" :class="{'border-success' : payWay == 'balance'}" style="position: relative;">
                            <b-form-radio value="balance" style="position: absolute;top:5px;left:10px"></b-form-radio>
                            <div class="balance">
                                <div>余额支付</div>
                                <div>
                                    <span>账户余额：</span>
                                    <span class="text-success">0元</span>
                                </div>
                            </div>
                        </div>
                    </b-form-radio-group>
                </div>
                <div class="clearfix p-3">
                    <div class="float-right">
                        <span>应付金额: </span>
                        <span class="text-danger">{{ReserveRecord[0].prepaid}}元</span>
                        <b-button class="ml-2" variant="danger" @click="payReserver()">立即支付</b-button>
                    </div>
                </div>
            </div>
      </div>
    </div>
    <map-footer></map-footer>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
        payWay: '',
        bizId: '',
        userNeeds:[],
        ReserveRecord:[]


    };
  },
  computed:{
    ...mapGetters(["dict"]),
    getcirculationTypeValue () {
      return function(id) {
        if(this.dict && this.dict.circulationType) {
          let m = this.dict.circulationType.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getreserveTypeValue () {
      return function(id) {
        if(this.dict && this.dict.reserveType) {
          let m = this.dict.reserveType.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getTagsListValue() {
      return function(j) {
        let b= '';
        if(this.dict && this.dict.landTags) {
          var tag = j.split(',');
          var valueArr = this.dict.landTags.filter(x=> tag.includes(x.id )).map(x=>{return x.text});
          b = valueArr.join('/');
        }
        return b;
      }
    },
    getreserveStatusValue () {
      return function(id) {
        if(this.dict && this.dict.reserveStatus) {
          let m = this.dict.reserveStatus.find(x => x.id == id)
          if(m) {
            return m.text;
          }
        }
        return ''
      }
    },
    getUseLandValue()	{
      return function(code) {
        let value = '';
        if(this.dict && this.dict.landUseType) {
          let lp = null, lc = null;
          lp = this.dict.landUseType.find(x => x.children.find(y=> y.id  == code));
          if(lp) {
            value = lp.text;
            lc = lp.children.find(x=> x.id == code);
            if(lc) {
              value = value + '/' + lc.text;
            }
          }
        }
        return value
      }
    },
  },
  methods: {

    findLand(){
      //查询招租信息接口
      this.$http.post(this.$api.supply.getLandDetail+"/"+this.bizId).then(res => {
        if (res.code == 1) {
          this.userNeeds=res.result;
        }
      })
    },
    findReserveRecord(){
      //查询带看订单详情
      this.$http.post(this.$api.reserve.findReserveRecordById+this.bizId).then(res => {
        if (res.code == 1) {
          debugger;
          this.ReserveRecord=res.result;
        }
      })
    },
    payReserver(){
      this.$http.post(this.$api.reserve.payForReserve+id).then((res) => {
      });
    },
    init(){
     this.findLand();
     this.findReserveRecord();
    },


  },

  created() {
    if(this.$route.query.bizId) {
      this.bizId = this.$route.query.bizId;
    }
    this.init();
  },
};
</script>

<style scoped>
    .order {
        background-color: #f5f5f5;
    }
    .balance {
        width:144px;height:55px;display: flex; flex-direction: column; justify-content: center;
    }
</style>
